// Fill this object with the values found in the config.js file that is stored in your S3 Bucket for local development

let devWebConfig = {
    region: 'us-east-1', // Fill in from region in config.js
    userPoolId: 'us-east-1_L5z41NLiy', // Fill in from userPoolId in config.js
    userPoolWebClientId: '7allb98tf45d7alp0ibmb7komu', // Fill in from userPoolWebClientId in config.js
    domain: 'defender.rmmao.people.aws.dev',
    cognitoDomain: 'Defender-448084530872.auth.us-east-1.amazoncognito.com', // Fill in from cognitoDomain in config.js
}

export default devWebConfig